import HyphenInsights from "@screens/Insights/Settings/components/HyphenInsights";
import ActionPlanSettingsManageTemplates from "@screens/Insights/Settings/components/ActionPlanSettingsManage";
import notificationActionCreators from "@src/store/notifications/actions";
import { Component } from "react";
import Survey from "@screens/Insights/Settings/components/Survey";
import { AppSettingsResource } from "@hyphen-lib/domain/resource/AppSettingsResource";
import { CompanyResource } from "@hyphen-lib/domain/resource/CompanyResource";
import { connect } from "react-redux";
import {
  getOr,
  getProperty,
  isEmptyObject,
  isNotNullNorUndefined,
  isNullOrUndefined,
  mapOr,
} from "@hyphen-lib/lang/Objects";
import { State } from "@store/types";
import { Rights } from "@hyphen-lib/business/auth/Rights";
import { RightsMatcher } from "@hyphen-lib/business/auth/Auth";
import { CurrentUserResource } from "@hyphen-lib/domain/resource/user/CurrentUserResource";
import { Notification } from "@hyphen-lib/domain/trait/WithNotifications";
import { Optional } from "@hyphen-lib/lang/Optionals";
import LikertScaleSettings from "../components/LikertScaleSettings";
import ActionPlanSettings from "../components/ActionPlanSettings";
import * as selectors from "../../store/selectors";
import { settingsActionCreators } from "../store/actions";
import {
  getCurrentUser,
  getCurrentUserNotification,
} from "../../store/selectors";
import { getAppSettings } from "../store/selectors";
import { Apps } from "hyphen-lib/dist/domain/apps/App";
import {
  isOkrEnabled,
  isAllowSMSEnabled,
  isMultiLanguageSurveyEnabled,
  isCoreAppUserSyncEnabled,
  showAnonymousBanner
} from "hyphen-lib/dist/business/company/Companies";
import MultilanguageSettings from "../components/MultiLanguageSettings";
import UserSyncToggle from "../components/UserSyncToggle";
import { checkIfHyphenAdmin } from "hyphen-lib/dist/business/user/Users";
import { withTranslation } from "react-i18next";
import { SMSToggle } from "../components/SMSToggle";

interface ReducerProps {
  appSettings: AppSettingsResource;
  companyName: CompanyResource["name"];
  readonly rightsMatcher: RightsMatcher;
  readonly notificationMessage: Notification["message"];
  readonly notificationId: Optional<Notification["_id"]>;
  readonly userId: CurrentUserResource["_id"];
  readonly email: CurrentUserResource["email"];
  readonly betterworksOkrEnabled: boolean;
  readonly isMultiLanguageSurveyEnabled: boolean;
  readonly isCoreAppUserSyncEnabled: boolean;
  readonly isAllowSMSEnabled: boolean;
  readonly showAnonymousBanner: boolean;
}

export type AppSettingsProps = typeof settingsActionCreators &
  typeof notificationActionCreators &
  ReducerProps;

export class AppSettings extends Component<AppSettingsProps> {
  componentDidMount() {
    const { appSettings, companyName, fetchAppSettings } = this.props;
    if (isEmptyObject(appSettings)) {
      fetchAppSettings(companyName);
    }
  }

  handleSettingsFormChange = (values: AppSettingsResource) => {
    this.props.updateAppSettings(this.props.companyName, values);
  };

  handleCloseNotification = () => {
    const { userId, notificationId, updateUserNotifications } = this.props;
    if (isNotNullNorUndefined(notificationId)) {
      updateUserNotifications(userId, notificationId);
    }
  };

  handleLanguageChange = (values: AppSettingsResource) => {
    this.props.updateAppSettings(this.props.companyName, values);
  };

  handleModulesChange = (values: AppSettingsResource) => {
    this.props.updateAppSettings(this.props.companyName, values);
  };

  handleAllowSMSFlagChange = (values: AppSettingsResource) => {
    this.props.updateAppSettings(this.props.companyName, values);
  };

  handleAnonymousBannerChange = (values: AppSettingsResource) => {
    this.props.updateAppSettings(this.props.companyName, values);
  };
  render() {
    const {
      appSettings,
      rightsMatcher,
      notificationMessage,
      isMultiLanguageSurveyEnabled,
      isCoreAppUserSyncEnabled,
      email,
      isAllowSMSEnabled,
      showAnonymousBanner
    } = this.props;
    const hasEditRight = rightsMatcher.hasRight(Rights.Action.EDIT_TEMPLATE);
    return (
      <>
        <HyphenInsights
          appSettings={appSettings}
          onValueChange={this.handleSettingsFormChange}
          notificationMessage={notificationMessage}
          onCloseNotification={this.handleCloseNotification}
        />
        <Survey
          appSettings={appSettings}
          onValueChange={this.handleSettingsFormChange}
          onAnonymousBannerChange={this.handleAnonymousBannerChange}
          showAnonymousBanner={showAnonymousBanner}
          email={email}
        />
        <LikertScaleSettings
          appSettings={appSettings}
          onValueChange={this.handleSettingsFormChange}
        />
        <MultilanguageSettings
          appSettings={appSettings}
          onValueChange={this.handleLanguageChange}
          handleModulesChange={this.handleModulesChange}
          isMultiLanguageSurveyEnabled={isMultiLanguageSurveyEnabled}
          email={email}
        />
        {
          isNotNullNorUndefined(appSettings.coreAppConfig) && appSettings.coreAppConfig.orgId ? 
          <UserSyncToggle
            appSettings={appSettings}
            onValueChange={this.handleLanguageChange}
            isCoreAppUserSyncEnabled={isCoreAppUserSyncEnabled}
          /> : null
        }
        <ActionPlanSettings
          betterworksOkrEnabled={this.props.betterworksOkrEnabled}
          appSettings={appSettings}
          onValueChange={this.handleSettingsFormChange}
        />
        <ActionPlanSettingsManageTemplates hasEditRight={hasEditRight} />
        {checkIfHyphenAdmin(email) &&
          (<SMSToggle
            onValueChange={this.handleAllowSMSFlagChange}
            appSettings={appSettings}
            isAllowSMSEnabled={isAllowSMSEnabled}
          />)}
      </>
    );
  }
}

const mapStateToProps = (state: State): ReducerProps => {
  let appSettings = getAppSettings(state).get(0);
  if (isNullOrUndefined(appSettings)) {
    appSettings = {} as AppSettingsResource;
  }

  let companyName = "";
  let notification = {} as Notification;
  let userId = "";
  let email = "";
  const currentUser = getCurrentUser(state);
  const betterworksOkrEnabled =
    (currentUser && currentUser.company && isOkrEnabled(currentUser.company)) ||
    false;

  if (
    isNotNullNorUndefined(currentUser) &&
    isNotNullNorUndefined(currentUser.company) &&
    isNotNullNorUndefined(currentUser.company.name)
  ) {
    companyName = currentUser.company.name;
    notification = getCurrentUserNotification(
      state,
      Apps.Pages.INSIGHTS_APP_SETTINGS_BENCHMARKS
    );
    const { userId: currentUserId, email: currentUserEmail } = mapOr(
      currentUser,
      (_currentUser) => ({
        userId: _currentUser._id,
        email: _currentUser.email,
      }),
      {
        userId: "",
        email: "",
      }
    );
    userId = currentUserId;
    email = currentUserEmail;
  }

  const checkMultiLanguageSurveyEnabled = isNotNullNorUndefined(currentUser) &&
    isNotNullNorUndefined(currentUser?.company) &&
    isMultiLanguageSurveyEnabled(currentUser.company);

  const checkShowAnonymousBanner = isNotNullNorUndefined(currentUser) &&
    isNotNullNorUndefined(currentUser?.company) &&
    showAnonymousBanner(currentUser.company);
  return {
    appSettings,
    companyName,
    userId,
    email,
    betterworksOkrEnabled,
    rightsMatcher: selectors.getRightsMatcher(state),
    notificationMessage: notification.message,
    notificationId: getOr(notification._id, Optional.empty() as string),
    isMultiLanguageSurveyEnabled: isNotNullNorUndefined(appSettings) &&
      isNotNullNorUndefined(appSettings.modules) ?
      getProperty(appSettings.modules, "multiLanguageSurvey", false)
      : checkMultiLanguageSurveyEnabled,
    isCoreAppUserSyncEnabled:
      isNotNullNorUndefined(currentUser) &&
      isNotNullNorUndefined(currentUser?.company) && 
      isCoreAppUserSyncEnabled(currentUser.company),
    isAllowSMSEnabled:
      isNotNullNorUndefined(currentUser) &&
      isNotNullNorUndefined(currentUser?.company) && 
      isAllowSMSEnabled(currentUser.company),
    showAnonymousBanner:
    isNotNullNorUndefined(appSettings) &&
    isNotNullNorUndefined(appSettings.modules) ?
    getProperty(appSettings.modules, "anonymousBanner", true)
    : checkShowAnonymousBanner,
  };
};

export default connect(mapStateToProps, {
  ...settingsActionCreators,
  ...notificationActionCreators,
})(withTranslation()(AppSettings));
