import React, { Component, MouseEvent } from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";
import { Card, Icon } from "antd";
import Input from "@components/core/Input";
import Button from "@components/core/Button";
import { connect } from "react-redux";
import { State } from "@store/types";
import { isNotNullNorUndefined } from "hyphen-lib/dist/lang/Objects";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import * as selectors from "@screens/Insights/store/selectors";
import Palette from "@src/config/theme/palette";
import { loadSessionToken, saveSessionToken } from "@src/utils/sessionStores";
import { isEmailFormatValid } from "hyphen-lib/dist/business/user/Users";
import { goTo } from "@src/utils/locations";
import { fetchCurrentUserIfNeeded } from "@store/network/resource/CurrentUserResources";
import { isStringAndNotEmpty } from "hyphen-lib/dist/lang/Strings";
import { User } from "hyphen-lib/dist/domain/User";
import { actionCreators } from "../../store/actions";
import { validateCode } from "../utils";
import bwEngageName from "./../assets/bw-name.svg";
import { Config } from "hyphen-lib/dist/util/Config";
import { AnalyticsBrowser } from "@segment/analytics-next";

// Hard Coded App Id
const APP_ID = "62aa263f6702b4e7552e0ffd66cd17ca76eb0a39";

const config = Config.getInstance();
const segmentApiKey = config.get("SEGMENT_API_KEY");
const analytics = AnalyticsBrowser.load({ writeKey: segmentApiKey });

interface States {
  email: string;
  password: string;
}

interface MatchParams {
  readonly userName: string;
  readonly passCode: string;
}

type OwnProps = RouteComponentProps<MatchParams>;

interface ReducerProps {
  isLoading: boolean;
  emailSuccess: boolean;
  emailFailure: boolean;
  emailFailureMessage: Optional<string>;
  passwordFailure: boolean;
  passwordSuccess: boolean;
  currentUser: Optional<CurrentUserResource>;
  passwordFailureMessage: Optional<string>;
}

interface LoginContainerActionProps {
  readonly onFetchCurrentUser: () => void;
}

export type LoginSettingsProps =
  LoginContainerActionProps &
  typeof actionCreators &
  ReducerProps &
  OwnProps;

export class LoginContainer extends Component<LoginSettingsProps,States>{

  state = {
    email: "",
    password: "",
  };

  componentWillUnmount() {
    this.refreshLogin();
  }

  componentDidMount() {
    const webURL = window.location.href;
    const { params } = this.props.match;
    const email = isEmailFormatValid(params.userName) ? params.userName : null;
    if(isNotNullNorUndefined(params.passCode) && isNotNullNorUndefined(email)) {
      this.props.loginRequest(email, {username: email,insights: true,
        password: params.passCode,
        _ApplicationId: APP_ID});
    }

    const token = webURL.indexOf("?x-session-token=") < 0 ? "" : webURL.slice(webURL.indexOf("?x-session-token=") + 17);

    if  (token !== "") {
      saveSessionToken(token);
      goTo("/");
    }

    const localSessionToken = token !== "" ? token : loadSessionToken();
    const { currentUser, onFetchCurrentUser } = this.props;
    if (isNotNullNorUndefined(localSessionToken) && !currentUser ) {
      onFetchCurrentUser();
    }
  }

  refreshLogin = () => {
    this.setState({email: "",password: ""});
    this.props.loginRefresh();
  };

  sendEmail = (e: any) => {
    e.preventDefault();
    this.props.loginUser(this.state.email,{email: this.state.email,insights: true,_ApplicationId: APP_ID});
  };

  loginUser = (e: any) => {
    e.preventDefault();
    const { email,password } = this.state;
    this.props.loginRequest(this.state.email,{username: email,insights: true,
      password,
      _ApplicationId: APP_ID});
  };

  renderWarning = () => {
    const { emailFailure, emailFailureMessage, isLoading } = this.props;

    if (emailFailure && isLoading === false && emailFailureMessage) {
      return <AlertParagraph ><Trans>{emailFailureMessage}</Trans></AlertParagraph>;
    } else if (emailFailure && isLoading === false && !emailFailureMessage) {
      return <AlertParagraph ><Trans>Please check your email & try again.</Trans></AlertParagraph>;
    }
  };

  resendConfirmationCode = (e: MouseEvent<HTMLAnchorElement>) => {
    this.setState({ password: "" }, () => {
      this.sendEmail(e);
    });
  };

  renderPasswordWarning = () => {
    const { passwordFailure, passwordFailureMessage, isLoading } = this.props;
    if (passwordFailure && isLoading === false) {
      if (
        isStringAndNotEmpty(passwordFailureMessage) &&
        passwordFailureMessage === User.CONFIRMATION_CODE_EXPIRED
      ) {
        return (
          <AlertParagraph>
            <Trans>Confirmation code expired!</Trans>
            &nbsp;<ResendLink onClick={this.resendConfirmationCode}>Click here to resend the code</ResendLink>
          </AlertParagraph>
        );
      }
      return <AlertParagraph><Trans>Please check your password & try again.</Trans></AlertParagraph>;
    }

    return "";
  };

  onChangeEmail = (e: any) => {
    this.setState({email: e.target.value.toLowerCase()});
  };

  returnStepOne = () => {

    return <LoginDiv>
      <GreyParagraph>What is your work email address ?</GreyParagraph>
      <StyledForm onSubmit={this.sendEmail} >
        <Input
          autoFocus
          onChange={this.onChangeEmail}
          placeholder="you@work.com "
          addonBefore={<Icon type="mail" />}
        />

        <InfoDiv>
          <StyledButton size="small"
            htmlType="submit"
            loading={this.props.isLoading ? true : false}
            color={isEmailFormatValid(this.state.email) ? "blue" : "grey"}
            disabled={!isEmailFormatValid(this.state.email)}>
                Send me a verification code
          </StyledButton>

          <HelpInfo>

            {this.renderWarning()}

            <GreyParagraph>Access is reserved to managers approved for Betterworks Engage Insights.</GreyParagraph>
          </HelpInfo>
        </InfoDiv>
      </StyledForm>
    </LoginDiv>;
  };

  onChangeCode = (e: any) => this.setState({password: e.target.value});

  returnStepTwo = () => {
    const { password } = this.state;
    return  <LoginDiv>
      <StyledForm onSubmit={this.loginUser}>
        <GreyParagraph>A code was sent to {this.state.email}. Please enter it to proceed.</GreyParagraph>
        <Input
          autoFocus
          onChange={this.onChangeCode}
          placeholder="Verification Code"
          type="password"
          value={password}
          addonBefore={<Icon type="key"/>}
        />

        <InfoDiv>

          <StyledButton size="small"
            htmlType="submit"
            loading={this.props.isLoading ? true : false}
            color={validateCode(this.state.password) ? "blue" : "grey"}
            disabled={!validateCode(this.state.password)}>
                    Proceed
          </StyledButton>
          <HelpInfo>
            {this.renderPasswordWarning()}
            <StyledLink
              onClick={this.refreshLogin}
            >
                      Entered the wrong email address ?
            </StyledLink>
          </HelpInfo>
        </InfoDiv>
      </StyledForm>
    </LoginDiv>;
  };

  renderSteps = () => this.props.emailSuccess ? this.returnStepTwo() : this.returnStepOne() ;

  render() {
    const {currentUser} = this.props;
    if (isNotNullNorUndefined(currentUser)) {
      analytics.identify(currentUser._id, {
        app: "engage",
        email: currentUser.email,
        company: currentUser.company?.name,
        roles: currentUser.roles
      });
      analytics.group(currentUser.company?.name, {});
      return (
        <Redirect to="/" />
      );
    }
    return(
      <Holder>
        <StyledCard>
          <HyphenDiv>
            <ImageDiv>
              <LogoImage src={bwEngageName} alt="Betterworks Engage logo" />
            </ImageDiv>
            <div>
              <StyledHeading>
                  Access to Betterworks Engage Insights for Admins and Managers
              </StyledHeading>
            </div>
          </HyphenDiv>
          {this.renderSteps()}
        </StyledCard>
        <Footer>
          <a target="_blank" rel="noreferrer" className="privacy-notice"
             href="https://www.betterworks.com/privacy-policy/">Privacy Notice</a> © 2022 Betterworks.
          All Rights Reserved.
        </Footer>
      </Holder>
    );
  }
}

const Holder = styled.div`
  display:flex;
  height:100vh;
  width:100vw;
  flex-direction:horizontal;
  justify-content:center;
  align-items:center;
  background-image:url("https://insights.gethyphen.com/imgs/shots/bg-1.jpg");
  background-repeat:no-repeat;
  background-size:cover;
`;

const Footer = styled.div`
  display: block;
  width: 100%;
  background-color: white;
  position: absolute;
  bottom: 0;
  height: 50px;
  line-height: 50px;
  text-align: center;

  a {
    color: rgba(0, 0, 0, 0.65)
  }
`;

const AlertParagraph = styled.p`
  color:${Palette.darkPink};
`;

const StyledButton = styled(Button)`
  padding-left:10px;
  padding-right:10px;
  margin:8px;
`;

const GreyParagraph = styled.p`
  color:${Palette.bluishGrey};
`;

const StyledForm = styled.form`

`;

const InfoDiv = styled.div`
  margin-top:10px;
`;

const LoginDiv = styled.div`
  display:flex;
  flex-direction:column;
  text-align:center;
`;

const HelpInfo = styled.div`
  margin-top:8px;
`;

const StyledCard = styled(Card)`
  min-width:400px;
  max-width:450px;
  min-height:450px;
  border-radius:5px;
`;

const HyphenDiv = styled.div`
  min-height:150px;
  background-color:${Palette.aquaBlue};
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  padding-top:12px;
  margin-bottom:10px;
`;

const ImageDiv = styled.div`
  margin-bottom:15px;
  margin-top:10px;
`;

const LogoImage = styled.img`
  width: 340px;
  margin-left: 10px;
  margin-right: 10px;
`;

const StyledLink = styled.a`
  text-decoration:none;
  color:${Palette.bluishGrey};
  display:block;
`;

const StyledHeading = styled.p`
  color:${Palette.white};
  font-size: 20px;
  text-align: center;
`;

const ResendLink = styled.a`
  color: ${Palette.bluePurple};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const mapStateToProps = (state: State): ReducerProps => {
  return {
    isLoading: state.insights.isLoading,
    emailSuccess: state.insights.emailSuccess,
    emailFailure: state.insights.emailFailure,
    emailFailureMessage: state.insights.emailFailureMessage,
    passwordFailure: state.insights.passwordFailure,
    passwordSuccess: state.insights.passwordSuccess,
    currentUser: selectors.getCurrentUser(state),
    passwordFailureMessage: state.insights.passwordFailureMessage,
  };
};

const mapDispatchToProps = {
  onFetchCurrentUser: fetchCurrentUserIfNeeded,
  ...actionCreators,
};

export const Login = withRouter(connect(mapStateToProps,mapDispatchToProps)(LoginContainer));
