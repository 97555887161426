import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps } from "react-router";

import { State } from "@store/types";

import { SurveyCommentsReportStateProps } from "@screens/Insights/Survey/components/Comments";
import OpenQuestion from "@screens/Insights/Survey/components/OpenQuestionHighlightReport";
import {
  getCurrentUser,
  getDimensions,
  getRightsMatcher,
} from "@screens/Insights/store/selectors";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { QuestionInfoResource } from "hyphen-lib/dist/domain/resource/QuestionInfoResource";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import Navbar from "@screens/Insights/Survey/components/Navbar";
import {
  getOr,
  getProperty,
  isNotEmptyObject,
  isNotNullNorUndefined,
  isNullOrUndefined,
  mapOr,
  objectOmit,
} from "hyphen-lib/dist/lang/Objects";
import { Map as ImmutableMap } from "immutable";
import { getParameters } from "@screens/Insights/parameters/store/selectors";
import { applyExistingParametersIfNeeded } from "@src/utils/parameters";
import { goTo, replaceLocation } from "@src/utils/locations";
import {
  checkForActionPlanCreationRight,
  checkForConversationRight,
  checkForSurveyReportRight,
} from "@src/utils/rights";
import { getAnonymityThreshold } from "@screens/Insights/Settings/store/selectors";
import { getExistingPage, getResourceById } from "@store/network/selectors";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { Loading } from "@screens/Insights/Survey/components/Loading";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { CommentsOverviewResource } from "hyphen-lib/dist/domain/resource/report/CommentsOverviewResource";
import { CommentsResultResource } from "hyphen-lib/dist/domain/resource/report/CommentsResultResource";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { fetchSurveyInfoIfNeeded } from "@store/network/resource/SurveyInfoResources";
import { fetchCommentResultsIfNeeded } from "@store/network/resource/CommentsResultResources";
import { fetchCommentsOverviewIfNeeded } from "@store/network/resource/CommentsOverviewResources";
import { fetchQuestionInfoIfNeeded } from "@store/network/resource/QuestionInfoResources";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { FetchError } from "@src/screens/Insights/errors/FetchError";
import { surveyReportActionCreators } from "../../store/actions";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import {
  focusAreaListActionCreators,
  FocusAreaListPageParameters,
} from "@src/screens/Insights/Actions/store/actions";
import { fetchFocusAreasIfNeeded } from "@src/store/network/resource/ActionResources";
import { isEmpty } from "lodash";
import {
  getAllFocusAreasFromReduxStore,
  getCurrentBottomDrawerView,
} from "@src/utils/FocusArea";
import BottomDrawer, { DrawerViews } from "@src/components/core/BottomDrawer";
import BottomDrawerStripedChild, {
  transformFocusAreasToStripStack,
} from "@src/components/core/BottomDrawer/StripedChild";
import { BottomDrawerMessages } from "@src/screens/Insights/BottomDrawer/constant";
import { hasFocusAreaRights } from "hyphen-lib/dist/business/focusArea/focusAreaResource";
import {
  fetchTopicsOverviewIfNeeded
} from "@src/store/network/resource/TopicsReportResources";
import { SurveyTopicCommentResource } from "hyphen-lib/dist/domain/resource/survey/report/SurveyTopicCommentResource";
import { parseNumber } from "hyphen-lib/dist/lang/Number";
import { formatSort } from "@src/utils/helper";
import { FilterParameter, SortParameter } from "@src/utils/networks";
interface MatchParams {
  id: string;
  questionId: string;
}

export interface OwnProps extends RouteComponentProps<MatchParams> {}

export interface QuestionCommentsHighlightReportContainerStateProps
  extends SurveyCommentsReportStateProps {
  readonly surveyId: string;
  readonly surveyInfoElement: Store.Element<SurveyInfoResource>;
  readonly questionInfoElement: Store.Element<QuestionInfoResource>;
  readonly parameters: ImmutableMap<string, any>;
  readonly canSendPrivateMessage: boolean;
  readonly anonymityThreshold: number;
  readonly comments: Store.Page<CommentsResultResource>;
  readonly commentsOverview: Store.Element<CommentsOverviewResource>;
  readonly dimensions: Optional<Dimensions>;
  readonly focusAreas?: FocusAreaResource[];
  readonly currentUser: Optional<CurrentUserResource>;
  readonly currentBottomDrawerView: DrawerViews;
  readonly hasFaRights: boolean;
  readonly hasTopicsAccess: boolean;
  readonly topicsOverview: Store.Element<SurveyTopicCommentResource>;
  readonly page: PageFilter;
  readonly sort: SortParameter;
  readonly filter: FilterParameter;
}

type QuestionCommentsHighlightReportContainerActionProps =
  typeof surveyReportActionCreators & {
    readonly onFetchSurveyInfo: (surveyId: string) => void;
    readonly onFetchQuestionInfo: (
      surveyId: string,
      questionId: string
    ) => void;
    readonly onFetchSurveyComments: (
      surveyId: string,
      questionId: string,
      queryString: Dictionary<any>
    ) => void;
    readonly onFetchSurveyCommentsOverview: (
      surveyId: string,
      questionId: string,
      queryString: Dictionary<any>
    ) => void;
    readonly onFetchFocusArea: (parameters: FocusAreaListPageParameters) => any;
    readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
    readonly onFetchTopicsOverview: (
      surveyId: string,
      queryString: Dictionary<any>
    ) => void;
  };

type Props = OwnProps &
  QuestionCommentsHighlightReportContainerStateProps &
  QuestionCommentsHighlightReportContainerActionProps;

interface QuestionCommentsHighlightReportContainerState {
    totalFocusAreas: number;
  };

class QuestionCommentsHighlightReportContainer extends React.Component
<Props, QuestionCommentsHighlightReportContainerState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      totalFocusAreas: 0
    };
  }

  componentDidMount(): void {
    // we might need to apply persisted parameters
    const {
      parameters,
      location: { search },
    } = this.props;

    const existing = parseQueryString(search);
    const mergedParameters = applyExistingParametersIfNeeded(
      parameters.toJS(),
      existing,
      "filter.dimensions",
      "filter.categories",
      "filter.sentiments"
    );

    if (
      isNotNullNorUndefined(mergedParameters) &&
      isNotEmptyObject(mergedParameters)
    ) {
      replaceLocation(mergedParameters);
    } else {
      // fetch the data only if we will stay on this page,
      // otherwise it will be fetched anyway in componentDidUpdate
      this.fetchDataFromBackend();
    }

    if (
     ( isNullOrUndefined(this.props.focusAreas) ||
      isEmpty(this.props.focusAreas)) && this.props.hasFaRights
    ) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }

    let totalFocusAreas = 0;
    if(isNotNullNorUndefined(this.props.focusAreas)) {
      totalFocusAreas = this.props.focusAreas.length;
    }

    this.setState({
      totalFocusAreas
    });
  }

  componentDidUpdate(prevProps: Props) {
    const {
      surveyInfoElement,
      questionInfoElement,
      commentsOverview,
      comments,
    } = this.props;
    const isInError =
      mapOr(
        surveyInfoElement,
        (sElem) => Store.Element.isInError(sElem),
        false
      ) ||
      mapOr(
        questionInfoElement,
        (qElem) => Store.Element.isInError(qElem),
        false
      ) ||
      mapOr(
        commentsOverview,
        (cElem) => Store.Element.isInError(cElem),
        false
      ) ||
      mapOr(comments, (cPage) => Store.Page.isInError(cPage), false);
    if (
      (this.props.match.params.id !== prevProps.match.params.id ||
        this.props.match.params.questionId !==
          prevProps.match.params.questionId ||
        this.props.location.search !== prevProps.location.search) &&
      not(isInError)
    ) {
      this.fetchDataFromBackend();
    }

    if(this.props.hasFaRights) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }

    if(isNotNullNorUndefined(this.props.focusAreas) && isNotNullNorUndefined(prevProps.focusAreas)) {
      if(this.props.focusAreas.length > prevProps.focusAreas.length) {
        this.setState({
          totalFocusAreas: this.props.focusAreas.length
        });
      }
    }
  }

  fetchFocusAreaProps(): FocusAreaListPageParameters {
    return {
      page: {
        number: 1,
        size: 30,
      },
      filter: {
        withActionPlans: false,
      },
    };
  }

  fetchDataFromBackend = () => {
    const questionId = this.props.match.params.questionId;
    const {
      location,
      surveyId,
      onFetchSurveyInfo,
      onFetchQuestionInfo,
      onFetchSurveyComments,
      onFetchSurveyCommentsOverview,
      onFetchTopicsOverview,
      hasTopicsAccess
    } = this.props;
    const queryParams = { ...parseQueryString(location.search), questionId };
    onFetchSurveyComments(surveyId, questionId, queryParams);
    onFetchSurveyCommentsOverview(surveyId, questionId, queryParams);
    onFetchSurveyInfo(surveyId);
    onFetchQuestionInfo(surveyId, questionId);
    const filter = getProperty(queryParams, "filter", {});
    filter.questionId = [questionId];
    if(isNotNullNorUndefined(filter.freeText)) {
      delete filter.freeText;
    }
    if(hasTopicsAccess) {
      onFetchTopicsOverview(
        surveyId, { filter } 
      );
    }
  };

  render() {
    const {
      surveyInfoElement,
      questionInfoElement,
      commentsOverview,
      comments,
      commentText,
      errorText,
      isNetworkRequesting,
      isPrivateMessageModalVisible,
      postId,
      privateMessageText,
      surveyId,
      voteId,
      canSendPrivateMessage,
      hasActionCreationRight,
      anonymityThreshold,
      clearPrivateMessageModal,
      sendPrivateMessage,
      togglePrivateMessageModal,
      updatePrivateMessageText,
      focusAreas,
      onCreateFocusArea,
      hasFaRights,
      topicsOverview,
      hasTopicsAccess,
      page,
      sort,
      filter
    } = this.props;

    if (Store.Element.isInError(surveyInfoElement)) {
      return (
        <FetchError
          {...surveyInfoElement}
          resourceType={SurveyInfoResource.TYPE}
        />
      );
    }
    if (Store.Element.isInError(questionInfoElement)) {
      return (
        <FetchError
          {...questionInfoElement}
          resourceType={QuestionInfoResource.TYPE}
        />
      );
    }
    if (Store.Element.isInError(commentsOverview)) {
      return (
        <FetchError
          {...commentsOverview}
          resourceType={CommentsOverviewResource.TYPE}
        />
      );
    }
    if (Store.Page.isInError(comments)) {
      return (
        <FetchError {...comments} resourceType={CommentsResultResource.TYPE} />
      );
    }
    if (Store.Element.isNotLoaded(surveyInfoElement)) {
      return <Loading />;
    }
    const {
      value: { participation, name, topic_analysis_enabled, surveySuggestionsEnabled, surveyLanguages },
    } = surveyInfoElement;

    const questionText = Store.Element.mapIfLoadedOr(
      questionInfoElement,
      (info) => info.question,
      ""
    );
    const allowComments = Store.Element.mapIfLoadedOr(
      questionInfoElement,
      (info) => info.allowComment,
      false
    );
    const displayTopics = topic_analysis_enabled && hasTopicsAccess;
    let enabledFilters = [ "dimension", "sentiment"];
    enabledFilters = displayTopics ? ["topic", ...enabledFilters] : enabledFilters;
    const isSentimentAnalysisQuestion = Store.Element.mapIfLoadedOr(
      questionInfoElement,
      (info) => info.isSentimentAnalysisQuestion,
      false
    );
    return (
      <>
        <Navbar
          style={{ marginBottom: 32, height: "auto" }}
          withActions={false}
          title={questionText}
        />
        {hasFaRights && <BottomDrawer
          key="segment-drawer"
          initialView={this.props.currentBottomDrawerView}
          title={`${this.state.totalFocusAreas === 0 ? "No" : this.state.totalFocusAreas} Focus Area Added`}
          footerButton={{
            display: true,
            text: "Go To Action Center",
            onClick: () => goTo("/actioncenter"),
          }}
        >
          <BottomDrawerStripedChild
            stripStack={transformFocusAreasToStripStack(focusAreas)}
            emptyStackMessage={BottomDrawerMessages.EMPTY_STACK_MESSAGE}
          />
        </BottomDrawer>}
        <OpenQuestion
          title={questionText}
          participation={participation}
          commentsOverview={Store.Element.toLoadable(commentsOverview)}
          comments={comments}
          topicAnalysEnabled={displayTopics}
          surveySuggestionsEnabled={surveySuggestionsEnabled}
          enabledFilters={["dimension", "topic", "sentiment"]}
          enabledCustomFilters={["addDimension"]}
          clearPrivateMessageModal={clearPrivateMessageModal}
          sendPrivateMessage={sendPrivateMessage}
          togglePrivateMessageModal={togglePrivateMessageModal}
          updatePrivateMessageText={updatePrivateMessageText}
          commentText={commentText}
          errorText={errorText}
          isNetworkRequesting={isNetworkRequesting}
          isPrivateMessageModalVisible={isPrivateMessageModalVisible}
          postId={postId}
          privateMessageText={privateMessageText}
          surveyId={surveyId}
          voteId={voteId}
          canSendPrivateMessage={canSendPrivateMessage}
          hasActionCreationRight={hasActionCreationRight}
          anonymityThreshold={anonymityThreshold}
          onCreateFocusArea={onCreateFocusArea}
          focusAreas={focusAreas}
          surveyName={name}
          topicsOverview={Store.Element.toLoadable(topicsOverview)}
          page={page}
          allowComments={allowComments}
          sort={sort}
          filter={filter}
          isSentimentAnalysisQuestion={isSentimentAnalysisQuestion}
          surveyLanguages={surveyLanguages}
        />
      </>
    );
  }
}

export const mapStateToProps: MapStateToProps<
  QuestionCommentsHighlightReportContainerStateProps,
  OwnProps,
  State
> = (
  state: State,
  ownProps: OwnProps
): QuestionCommentsHighlightReportContainerStateProps => {
  const currentUser = getCurrentUser(state);
  const {
    match: {
      params: { id: surveyId, questionId },
    },
  } = ownProps;
  const surveyInfoElement = getResourceById(
    state,
    SurveyInfoResource.TYPE,
    surveyId
  );
  const questionInfoElement = getResourceById(
    state,
    QuestionInfoResource.TYPE,
    questionId
  );
  const canSendPrivateMessage = Store.Element.isLoaded(surveyInfoElement)
    ? checkForConversationRight(surveyInfoElement.value.role)
    : false;
  const queryParameters = parseQueryString(ownProps.location.search);
  const page = {
    number: mapOr(queryParameters.page, parseNumber, 1),
    size: 10
  };
  const filter = queryParameters.filter;

  const data = getAllFocusAreasFromReduxStore(state);
  const currentBottomDrawerView = getCurrentBottomDrawerView(state);
  const hasFaRights = hasFocusAreaRights(getRightsMatcher(state));
  const skipFreeText = objectOmit(getOr(filter, {}), "freeText");
  const extendedFilter = { questionId: [questionId], ...skipFreeText };
  const sort = getOr(formatSort(queryParameters.sort), {});
  return {
    surveyId,
    surveyInfoElement,
    questionInfoElement,
    comments: getExistingPage(
      state,
      CommentsResultResource.TYPE,
      CommentsResultResource.generateKey({ surveyId, questionId }, filter),
      PageFilter.noPagination() // fixme: handle pagination at some point!
    ),
    commentsOverview: getResourceById(
      state,
      CommentsOverviewResource.TYPE,
      CommentsOverviewResource.generateId({ surveyId, questionId }, filter)
    ),
    dimensions: getDimensions(state),
    commentText: state.getIn(["insights_survey_report", "commentText"]),
    errorText: state.getIn(["insights_survey_report", "errorText"]),
    isNetworkRequesting: state.getIn([
      "insights_survey_report",
      "isSendMessageRequesting",
    ]),
    isPrivateMessageModalVisible: state.getIn([
      "insights_survey_report",
      "isPrivateMessageModalVisible",
    ]),
    postId: state.getIn(["insights_survey_report", "postId"]),
    privateMessageText: state.getIn([
      "insights_survey_report",
      "privateMessageText",
    ]),
    topicsOverview: getResourceById(
      state,
      SurveyTopicCommentResource.TYPE,
      SurveyTopicCommentResource.generateKey(
        surveyId,
        extendedFilter
      )
    ),
    voteId: state.getIn(["insights_survey_report", "voteId"]),
    parameters: getParameters(state),
    canSendPrivateMessage,
    hasActionCreationRight: checkForActionPlanCreationRight(state),
    anonymityThreshold: getAnonymityThreshold(state),
    hasTopicsAccess: checkForSurveyReportRight(
      getRightsMatcher(state),
      "topics"
    ),
    currentUser,
    focusAreas: data,
    currentBottomDrawerView,
    hasFaRights,
    page,
    sort,
    filter
  };
};

const mapDispatchToProps: QuestionCommentsHighlightReportContainerActionProps =
  {
    onFetchSurveyInfo: fetchSurveyInfoIfNeeded,
    onFetchQuestionInfo: fetchQuestionInfoIfNeeded,
    onFetchSurveyComments: fetchCommentResultsIfNeeded,
    onFetchSurveyCommentsOverview: fetchCommentsOverviewIfNeeded,
    onFetchFocusArea: fetchFocusAreasIfNeeded,
    onCreateFocusArea: focusAreaListActionCreators.createFocusArea,
    onFetchTopicsOverview: fetchTopicsOverviewIfNeeded,
    ...surveyReportActionCreators,
  };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionCommentsHighlightReportContainer);
export { QuestionCommentsHighlightReportContainer as PureQuestionCommentsHighlightReportContainer };
