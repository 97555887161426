import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";
import { State } from "@store/types";

import { SurveyCommentsReportStateProps } from "@screens/Insights/Survey/components/Comments";
import {
  getCurrentUser,
  getDimensions,
  getRightsMatcher,
} from "@screens/Insights/store/selectors";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import Navbar from "@screens/Insights/Survey/components/Navbar";
import OpenQuestion from "@screens/Insights/Survey/components/OpenQuestionHighlightReport";
import {
  addPropertyInDepth,
  getOr,
  isNotEmptyObject,
  isNotNullNorUndefined,
  isNullOrUndefined,
  mapOr,
  objectOmit,
} from "hyphen-lib/dist/lang/Objects";
import { Map as ImmutableMap } from "immutable";
import { getParameters } from "@screens/Insights/parameters/store/selectors";
import { applyExistingParametersIfNeeded } from "@src/utils/parameters";
import {goTo, replaceLocation } from "@src/utils/locations";
import {
  checkForActionPlanCreationRight,
  checkForConversationRight,
} from "@src/utils/rights";
import { getAnonymityThreshold } from "@screens/Insights/Settings/store/selectors";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { SurveyInfoResource } from "hyphen-lib/dist/domain/resource/SurveyInfoResource";
import { fetchSurveyInfoIfNeeded } from "@store/network/resource/SurveyInfoResources";
import {
  getExistingPage,
  getResourceById,
} from "@store/network/selectors";
import { Loading } from "@screens/Insights/Survey/components/Loading";
import { Dimensions } from "hyphen-lib/dist/domain/common/Dimensions";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { CommentsResultResource } from "hyphen-lib/dist/domain/resource/report/CommentsResultResource";
import { CommentsOverviewResource } from "hyphen-lib/dist/domain/resource/report/CommentsOverviewResource";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { fetchCommentResultsIfNeeded } from "@store/network/resource/CommentsResultResources";
import { fetchCommentsOverviewIfNeeded } from "@store/network/resource/CommentsOverviewResources";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { FetchError } from "@src/screens/Insights/errors/FetchError";
import { surveyReportActionCreators } from "../../store/actions";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import { fetchFocusAreasIfNeeded } from "@src/store/network/resource/ActionResources";
import {
  focusAreaListActionCreators,
  FocusAreaListPageParameters,
} from "@src/screens/Insights/Actions/store/actions";
import { isEmpty } from "lodash";
import { getAllFocusAreasFromReduxStore, getCurrentBottomDrawerView } from "@src/utils/FocusArea";
import { hasFocusAreaRights } from "hyphen-lib/dist/business/focusArea/focusAreaResource";
import Palette from "@src/config/theme/palette";
import ArtificialInteligence from "@src/components/core/svg/ArtificialInteligence";
import { fetchTopicSegmentReportIfNeeded } from "@src/store/network/resource/TopicSegmentReportResource";
import { parseNumber } from "hyphen-lib/dist/lang/Number";
import { formatSort } from "@src/utils/helper";
import { FilterParameter, SortParameter } from "@src/utils/networks";
import BottomDrawer, { DrawerViews } from "@src/components/core/BottomDrawer";
import BottomDrawerStripedChild, 
  { transformFocusAreasToStripStack } from "@src/components/core/BottomDrawer/StripedChild";
import { BottomDrawerMessages } from "@src/screens/Insights/BottomDrawer/constant";
import {  SurveyTopicSegmentResource } from "hyphen-lib/dist/domain/resource/survey/report/SurveyTopicSegmentResource";
import { fetchSurveyParticipationIfNeeded } from "@src/store/network/resource/ParticipationReportResources";
interface MatchParams {
  id: string;
  topicId: string;
  questionId: string;
  categoryId: string;
}

export interface OwnProps extends RouteComponentProps<MatchParams> {}

export interface TopicCommentsHighlightReportContainerStateProps
  extends SurveyCommentsReportStateProps {
  readonly surveyId: string;
  readonly surveyInfoElement: Store.Element<SurveyInfoResource>;
  readonly topicsSegment: Store.Element<SurveyTopicSegmentResource>;
  readonly parameters: ImmutableMap<string, any>;
  readonly canSendPrivateMessage: boolean;
  readonly anonymityThreshold: number;
  readonly comments: Store.Page<CommentsResultResource>;
  readonly commentsOverview: Store.Element<CommentsOverviewResource>;
  readonly dimensions: Optional<Dimensions>;
  readonly focusAreas?: FocusAreaResource[];
  readonly currentUser: Optional<CurrentUserResource>;
  readonly hasFaRights: boolean;
  readonly currentBottomDrawerView: DrawerViews;
  readonly page: PageFilter;
  readonly sort: SortParameter;
  readonly filter: FilterParameter;
}

type TopicCommentsHighlightReportContainerActionProps =
  typeof surveyReportActionCreators & {
    readonly onFetchSurveyInfo: (surveyId: string) => void;
    readonly onFetchSurveyComments: (
      surveyId: string,
      questionId: Optional<string>,
      queryString: Dictionary<any>
    ) => void;
    readonly onFetchSurveyCommentsOverview: (
      surveyId: string,
      questionId: Optional<string>,
      queryString: Dictionary<any>
    ) => void;
    readonly onFetchFocusArea: (parameters: FocusAreaListPageParameters) => any;
    readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
    readonly onFetchTopicSegmentReport:
    (surveyId: string, questionId: string, queryString: Dictionary<any>) => any;
    readonly onFetchParticipation: (surveyId: string, queryString: Dictionary<any>) => void;
  };

  interface TopicCommentsHighlightReportContainerState {
    totalFocusAreas: number;
  }

export type Props = OwnProps &
  TopicCommentsHighlightReportContainerStateProps &
  TopicCommentsHighlightReportContainerActionProps ;

export class TopicCommentsHighlightReportContainer extends React.Component<
  Props,
  TopicCommentsHighlightReportContainerState
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      totalFocusAreas: 0,
    };
  }
  
  componentDidMount(): void {
    // we might need to apply persisted parameters
    const {
      parameters,
      location: { search },
    } = this.props;

    const existing = parseQueryString(search);
    const mergedParameters = applyExistingParametersIfNeeded(
      parameters.toJS(),
      existing,
      "filter.dimensions",
      "filter.categories",
      "filter.sentiments"
    );

    if (
      isNotNullNorUndefined(mergedParameters) &&
      isNotEmptyObject(mergedParameters)
    ) {
      replaceLocation(mergedParameters);
    } else {
      // fetch the overview only if we will stay on this page,
      // otherwise it will be fetched anyway in componentDidUpdate
      this.fetchDataFromBackend();
    }

    if (
      (isNullOrUndefined(this.props.focusAreas) ||
      isEmpty(this.props.focusAreas)) && this.props.hasFaRights
    ) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }
    if (isNotNullNorUndefined(this.props.focusAreas)) {
      this.setState({
        totalFocusAreas: this.props.focusAreas.length,
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { surveyInfoElement, comments, commentsOverview } = this.props;
    const isInError =
      mapOr(
        surveyInfoElement,
        (sElem) => Store.Element.isInError(sElem),
        false
      ) ||
      mapOr(comments, (p) => Store.Page.isInError(p), false) ||
      mapOr(commentsOverview, (cElem) => Store.Element.isInError(cElem), false);
    if (
      (this.props.surveyId !== prevProps.surveyId ||
        this.props.match.params.topicId !==
          prevProps.match.params.topicId ||
        this.props.location.search !== prevProps.location.search ||
        this.props.postId !== prevProps.postId) &&
      not(isInError)
    ) {
      this.fetchDataFromBackend();
    }
    if(this.props.hasFaRights) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }

    if (
      isNotNullNorUndefined(this.props.focusAreas) &&
      isNotNullNorUndefined(prevProps.focusAreas)
    ) {
      if (this.props.focusAreas.length > prevProps.focusAreas.length) {
        this.setState({
          totalFocusAreas: this.props.focusAreas.length,
        });
      }
    }
  }

  fetchFocusAreaProps(): FocusAreaListPageParameters {
    return {
      page: {
        number: 1,
        size: 30,
      },
      filter: {
        withActionPlans: false,
      },
    };
  }

  extractRelevantParametersForQuery(parameters: any) {
    const relevantParameters = { ...parameters };
    if(isNotEmptyObject(relevantParameters.filter)) {
      delete relevantParameters.filter.freeText;
    }
    delete relevantParameters.sort;
    return relevantParameters;
  }

  fetchDataFromBackend = () => {
    const { topicId, questionId , categoryId} = this.props.match.params;
    const {
      location,
      surveyId,
      postId,
      onFetchSurveyInfo,
      onFetchSurveyComments,
      onFetchSurveyCommentsOverview,
      onFetchTopicSegmentReport,
      onFetchParticipation,
    } = this.props;
    const queryParams = addPropertyInDepth(
      parseQueryString(location.search),
      [topicId],
      "filter.topics"
    );
    if(isNotNullNorUndefined(questionId)){
      queryParams.questionId = questionId;
      queryParams.filter.questionId = questionId;
    } else if (isNotNullNorUndefined(categoryId)) {
      queryParams.filter.categories= [categoryId];
    }
    onFetchSurveyComments(surveyId, postId, queryParams);
    onFetchParticipation(surveyId, queryParams);
    onFetchSurveyCommentsOverview(surveyId, postId, this.extractRelevantParametersForQuery(queryParams));
    onFetchSurveyInfo(surveyId);
    onFetchTopicSegmentReport(surveyId, topicId, this.extractRelevantParametersForQuery(queryParams));
  };

  render() {
    const {
      match: {
        params: { topicId },
      },
      surveyInfoElement,
      commentsOverview,
      comments,
      commentText,
      errorText,
      isNetworkRequesting,
      isPrivateMessageModalVisible,
      postId,
      privateMessageText,
      surveyId,
      voteId,
      canSendPrivateMessage,
      hasActionCreationRight,
      anonymityThreshold,
      clearPrivateMessageModal,
      sendPrivateMessage,
      togglePrivateMessageModal,
      updatePrivateMessageText,
      focusAreas,
      onCreateFocusArea,
      topicsSegment,
      page,
      sort,
      filter,
      hasFaRights
    } = this.props;
    const { totalFocusAreas } = this.state;

    if (Store.Element.isInError(surveyInfoElement)) {
      return (
        <FetchError
          {...surveyInfoElement}
          resourceType={SurveyInfoResource.TYPE}
        />
      );
    }
    if (Store.Page.isInError(comments)) {
      return (
        <FetchError {...comments} resourceType={CommentsResultResource.TYPE} />
      );
    }

    if (Store.Element.isInError(commentsOverview)) {
      return (
        <FetchError
          {...commentsOverview}
          resourceType={CommentsOverviewResource.TYPE}
        />
      );
    }

    if (Store.Element.isNotLoaded(surveyInfoElement)) {
      return <Loading />;
    }

    const {
      value: { participation, name, topic_analysis_enabled, surveySuggestionsEnabled, surveyLanguages },
    } = surveyInfoElement;

    return (
      <>
        <Navbar
          data-jest="NavBar"
          style={{ marginBottom: 12, height: "auto" }}
          withActions={false}
          title={decodeURI(topicId)}
          customTag={{
            color: Palette.lightPeriwinkle,
            title: "AI-based topic",
            icon: <ArtificialInteligence/>
          }}
        />
        <SubTitle>
          Discovered for you by our AI model.
          {/* will add later */}
          {/* <a>Learn more</a>  */}
        </SubTitle>
        <OpenQuestion
          title={decodeURI(topicId)}
          participation={participation}
          topicsSegment={Store.Element.toLoadable(topicsSegment)}
          commentsOverview={Store.Element.toLoadable(commentsOverview)}
          comments={comments}
          enabledFilters={["dimension", "sentiment"]}
          enabledCustomFilters={["addDimension"]}
          singleFieldFilter="category"
          clearPrivateMessageModal={clearPrivateMessageModal}
          sendPrivateMessage={sendPrivateMessage}
          togglePrivateMessageModal={togglePrivateMessageModal}
          updatePrivateMessageText={updatePrivateMessageText}
          commentText={commentText}
          errorText={errorText}
          isNetworkRequesting={isNetworkRequesting}
          isPrivateMessageModalVisible={isPrivateMessageModalVisible}
          postId={postId}
          privateMessageText={privateMessageText}
          surveyId={surveyId}
          voteId={voteId}
          canSendPrivateMessage={canSendPrivateMessage}
          hasActionCreationRight={hasActionCreationRight}
          anonymityThreshold={anonymityThreshold}
          focusAreas={focusAreas}
          onCreateFocusArea={onCreateFocusArea}
          surveyName={name}
          page={page}
          sort={sort}
          filter={filter}
          topicAnalysEnabled={topic_analysis_enabled}
          surveySuggestionsEnabled={surveySuggestionsEnabled}
          surveyLanguages={surveyLanguages}
        />
         {hasFaRights && (
            <BottomDrawer
              key="segment-drawer"
              initialView={this.props.currentBottomDrawerView}
              title={`${
                totalFocusAreas === 0
                  ? "No"
                  : totalFocusAreas
              } Focus Area Added`}
              footerButton={{
                display: true,
                text: "Go To Action Center",
                onClick: () => goTo("/actioncenter"),
              }}
            >
              <BottomDrawerStripedChild
                stripStack={transformFocusAreasToStripStack(focusAreas)}
                emptyStackMessage={BottomDrawerMessages.EMPTY_STACK_MESSAGE}
              />
            </BottomDrawer>
          )}
      </>
    );
  }
}

export const mapStateToProps: MapStateToProps<
  TopicCommentsHighlightReportContainerStateProps,
  OwnProps,
  State
> = (
  state: State,
  ownProps: OwnProps
): TopicCommentsHighlightReportContainerStateProps => {
  const currentUser = getCurrentUser(state);
  const { match, location } = ownProps;
  const surveyId = match.params.id;
  const { topicId, questionId, categoryId } = match.params;
  const surveyInfoElement = getResourceById(
    state,
    SurveyInfoResource.TYPE,
    surveyId
  );
  const canSendPrivateMessage = Store.Element.isLoaded(surveyInfoElement)
    ? checkForConversationRight(surveyInfoElement.value.role)
    : false;
  const queryParams = addPropertyInDepth(
    parseQueryString(location.search),
    [topicId],
    "filter.topics"
  );
  if(isNotNullNorUndefined(questionId)) {
    queryParams.questionId = questionId;
    queryParams.filter.questionId = questionId;
  } else if (isNotNullNorUndefined(categoryId)) {
    queryParams.filter.categories= [categoryId];
  }
  const { filter } = queryParams;

  const postId = state.getIn(["insights_survey_report", "postId"]);
  const data = getAllFocusAreasFromReduxStore(state);
  const hasFaRights = hasFocusAreaRights(getRightsMatcher(state));
  const queryParameters = parseQueryString(ownProps.location.search);
  const page = {
    number: mapOr(queryParameters.page, parseNumber, 1),
    size: 10
  };
  const currentBottomDrawerView = getCurrentBottomDrawerView(state);
  const sort = getOr(formatSort(queryParameters.sort), {});
  return {
    surveyId,
    surveyInfoElement,
    topicsSegment: getResourceById(
      state,
      SurveyTopicSegmentResource.TYPE,
      SurveyTopicSegmentResource.generateKey(
        surveyId,
        objectOmit(filter, "freeText")
      )
    ),
    comments: getExistingPage(
      state,
      CommentsResultResource.TYPE,
      CommentsResultResource.generateKey(
        { surveyId, questionId: postId},
        filter
      ),
      PageFilter.noPagination() // fixme: handle pagination at some point!
    ),
    commentsOverview: getResourceById(
      state,
      CommentsOverviewResource.TYPE,
      CommentsOverviewResource.generateId(
        { surveyId, questionId: postId },
        // @ts-ignore
        objectOmit(getOr(filter, {}), "freeText")
      )
    ),
    dimensions: getDimensions(state),
    commentText: state.getIn(["insights_survey_report", "commentText"]),
    errorText: state.getIn(["insights_survey_report", "errorText"]),
    isNetworkRequesting: state.getIn([
      "insights_survey_report",
      "isSendMessageRequesting",
    ]),
    isPrivateMessageModalVisible: state.getIn([
      "insights_survey_report",
      "isPrivateMessageModalVisible",
    ]),
    postId,
    privateMessageText: state.getIn([
      "insights_survey_report",
      "privateMessageText",
    ]),
    voteId: state.getIn(["insights_survey_report", "voteId"]),
    parameters: getParameters(state),
    canSendPrivateMessage,
    hasActionCreationRight: checkForActionPlanCreationRight(state),
    anonymityThreshold: getAnonymityThreshold(state),
    currentUser,
    focusAreas: data,
    hasFaRights,
    page,
    sort,
    currentBottomDrawerView,
    filter
  };
};

const mapDispatchToProps: TopicCommentsHighlightReportContainerActionProps =
  {
    onFetchSurveyInfo: fetchSurveyInfoIfNeeded,
    onFetchSurveyComments: fetchCommentResultsIfNeeded,
    onFetchSurveyCommentsOverview: fetchCommentsOverviewIfNeeded,
    onFetchFocusArea: fetchFocusAreasIfNeeded,
    onFetchTopicSegmentReport: fetchTopicSegmentReportIfNeeded,
    onCreateFocusArea: focusAreaListActionCreators.createFocusArea,
    onFetchParticipation: fetchSurveyParticipationIfNeeded,
    ...surveyReportActionCreators,
  };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopicCommentsHighlightReportContainer);
export { TopicCommentsHighlightReportContainer as PureTopicCommentsHighlightReportContainer };

const SubTitle = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
  a {
    margin-left: 15px;
    color: ${Palette.violet};
    font-weight: bold;
  }
`;
