import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps } from "react-router";

import { State } from "@store/types";

import Comments, {
  SurveyCommentsReportStateProps,
} from "@screens/Insights/Survey/components/Comments";
import {
  getCurrentUser,
  getDimensions,
  getRightsMatcher,
} from "@screens/Insights/store/selectors";
import { parseQueryString } from "hyphen-lib/dist/util/net/HttpClient";
import { Map as ImmutableMap } from "immutable";
import { getParameters } from "@screens/Insights/parameters/store/selectors";
import { applyExistingParametersIfNeeded } from "@src/utils/parameters";
import { goTo, replaceLocation } from "@src/utils/locations";
import {
  getOr,
  isNotEmptyObject,
  isNotNullNorUndefined,
  isNullOrUndefined,
  mapOr,
} from "hyphen-lib/dist/lang/Objects";
import {
  checkForActionPlanCreationRight,
  checkForConversationRight,
} from "@src/utils/rights";
import { getAnonymityThreshold } from "@screens/Insights/Settings/store/selectors";
import { Participation } from "hyphen-lib/dist/domain/common/Participation";
import { AccessList } from "hyphen-lib/dist/domain/access/AccessList";
import { CommentsResultResource } from "hyphen-lib/dist/domain/resource/report/CommentsResultResource";
import { CommentsOverviewResource } from "hyphen-lib/dist/domain/resource/report/CommentsOverviewResource";
import { Store } from "hyphen-lib/dist/util/store/Store";
import { Dimensions } from "@hyphen-lib/domain/common/Dimensions";
import { Optional } from "hyphen-lib/dist/lang/Optionals";
import { Dictionary } from "hyphen-lib/dist/domain/structure/Dictionary";
import { getExistingPage, getResourceById } from "@store/network/selectors";
import { PageFilter } from "hyphen-lib/dist/domain/parameter/PageFilter";
import { fetchCommentsOverviewIfNeeded } from "@src/store/network/resource/CommentsOverviewResources";
import { fetchCommentResultsIfNeeded } from "@src/store/network/resource/CommentsResultResources";
import { not } from "hyphen-lib/dist/lang/Booleans";
import { FetchError } from "@src/screens/Insights/errors/FetchError";
import { surveyReportActionCreators } from "../../store/actions";
import {
  focusAreaListActionCreators,
  FocusAreaListPageParameters,
} from "@src/screens/Insights/Actions/store/actions";
import { FocusAreaResource } from "hyphen-lib/dist/domain/resource/focus/FocusAreaResource";
import { CurrentUserResource } from "hyphen-lib/dist/domain/resource/user/CurrentUserResource";
import { fetchFocusAreasIfNeeded } from "@src/store/network/resource/ActionResources";
import { isEmpty } from "lodash";
import BottomDrawer, { DrawerViews } from "@src/components/core/BottomDrawer";
import BottomDrawerStripedChild, {
  transformFocusAreasToStripStack,
} from "@src/components/core/BottomDrawer/StripedChild";
import {
  getAllFocusAreasFromReduxStore,
  getCurrentBottomDrawerView,
  isFARequestComplete,
} from "@src/utils/FocusArea";
import { BottomDrawerMessages } from "@src/screens/Insights/BottomDrawer/constant";
import { hasFocusAreaRights } from "hyphen-lib/dist/business/focusArea/focusAreaResource";
import Spin from "@src/components/core/Spin";
import { SortParameter } from "@src/utils/networks";
import { formatSort } from "@src/utils/helper";
import { parseNumber } from "hyphen-lib/dist/lang/Number";
import { isNotEmptyArray } from "hyphen-lib/dist/lang/Arrays";
import { filterEnglishCodes } from "@src/utils/translation";

interface MatchParams {
  id: string;
  selectedDimension: string;
}

export interface OwnProps extends RouteComponentProps<MatchParams> {
  readonly surveyId: string;
  readonly participation: Participation;
  readonly surveyRole: AccessList.UserAccessRole;
  readonly surveyName: string;
}

export interface CommentsContainerStateProps {
  readonly parameters: ImmutableMap<string, any>;
  readonly canSendPrivateMessage: boolean;
  readonly anonymityThreshold: number;
  readonly comments: Store.Page<CommentsResultResource>;
  readonly loading: boolean;
  readonly commentsOverview: Store.Element<CommentsOverviewResource>;
  readonly dimensions: Optional<Dimensions>;
  readonly focusAreas?: FocusAreaResource[];
  readonly currentUser: Optional<CurrentUserResource>;
  readonly currentBottomDrawerView: DrawerViews;
  readonly hasFaRights: boolean;
  readonly completedFARequest: boolean;
  readonly sort: SortParameter;
  readonly topicAnalysEnabled?: boolean;
  readonly surveySuggestionsEnabled?: boolean;
  readonly page: PageFilter;
  readonly postAndSurveySentiment?: boolean;
  readonly surveyLanguages?: Optional<string[]>;
}

export type CommentsContainerActionProps = typeof surveyReportActionCreators & {
  readonly onFetchSurveyComments: (
    surveyId: string,
    questionId: Optional<string>,
    queryString: Dictionary<any>
  ) => void;
  readonly onFetchSurveyCommentsOverview: (
    surveyId: string,
    questionId: Optional<string>,
    queryString: Dictionary<any>
  ) => void;
  readonly onFetchFocusArea: (parameters: FocusAreaListPageParameters) => any;
  readonly onCreateFocusArea: (focusArea: Partial<FocusAreaResource>) => void;
};

type Props = OwnProps &
  CommentsContainerStateProps &
  CommentsContainerActionProps &
  SurveyCommentsReportStateProps; // fixme extract a common type if needed, but not the type from another component!

interface CommentsContainerState {
  totalFocusAreas: number;
}

class CommentsContainer extends React.Component<Props, CommentsContainerState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      totalFocusAreas: 0,
    };
  }

  componentDidMount(): void {
    // we might need to apply persisted parameters
    const {
      parameters,
      location: { search },
    } = this.props;

    const existing = parseQueryString(search);
    const mergedParameters = applyExistingParametersIfNeeded(
      parameters.toJS(),
      existing,
      "filter.dimensions",
      "filter.categories",
      "filetr.topics",
      "filter.sentiments"
    );
    if (
      isNotNullNorUndefined(mergedParameters) &&
      isNotEmptyObject(mergedParameters)
    ) {
      replaceLocation(mergedParameters);
    } else {
      // fetch data only if we will stay on this page,
      // otherwise it will be fetched anyway in componentDidUpdate
      this.fetchDataFromBackend();
    }

    if (
      (isNullOrUndefined(this.props.focusAreas) ||
        isEmpty(this.props.focusAreas)) &&
      this.props.hasFaRights
    ) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }

    let totalFocusAreas = 0;
    if (isNotNullNorUndefined(this.props.focusAreas)) {
      totalFocusAreas = this.props.focusAreas.length;
    }

    this.setState({
      totalFocusAreas,
    });
  }

  componentDidUpdate(prevProps: Props) {
    const { comments, commentsOverview } = this.props;
    const isInError =
      mapOr(comments, (c) => Store.Page.isInError(c), false) ||
      mapOr(commentsOverview, (co) => Store.Element.isInError(co), false);
    if (
      (this.props.surveyId !== prevProps.surveyId ||
        this.props.location.search !== prevProps.location.search ||
        this.props.loading) &&
      not(isInError)
    ) {
      this.fetchDataFromBackend();
    }

    if (this.props.hasFaRights) {
      this.props.onFetchFocusArea(this.fetchFocusAreaProps());
    }

    if (
      isNotNullNorUndefined(this.props.focusAreas) &&
      isNotNullNorUndefined(prevProps.focusAreas)
    ) {
      if (this.props.focusAreas.length > prevProps.focusAreas.length) {
        this.setState({
          totalFocusAreas: this.props.focusAreas.length,
        });
      }
    }
  }

  fetchFocusAreaProps(): FocusAreaListPageParameters {
    return {
      page: {
        number: 1,
        size: 30,
      },
      filter: {
        withActionPlans: false,
      },
    };
  }

  extractRelevantParametersForQuery(parameters: any) {
    const relevantParameters = { ...parameters };
    delete relevantParameters.freeText;
    delete relevantParameters.sort;
    return relevantParameters;
  }

  fetchDataFromBackend = () => {
    const {
      location,
      surveyId,
      onFetchSurveyComments,
      onFetchSurveyCommentsOverview,
    } = this.props;
    const queryParams = this.extractRelevantParametersForQuery(
      parseQueryString(location.search)
    );
    onFetchSurveyComments(surveyId, Optional.empty(), queryParams);
    onFetchSurveyCommentsOverview(surveyId, Optional.empty(), queryParams);
  };

  render() {
    const {
      comments,
      commentsOverview,
      participation,
      commentText,
      errorText,
      isNetworkRequesting,
      isPrivateMessageModalVisible,
      postId,
      privateMessageText,
      surveyId,
      surveyName,
      voteId,
      canSendPrivateMessage,
      hasActionCreationRight,
      anonymityThreshold,
      clearPrivateMessageModal,
      sendPrivateMessage,
      togglePrivateMessageModal,
      updatePrivateMessageText,
      focusAreas,
      onCreateFocusArea,
      hasFaRights,
      completedFARequest,
      sort,
      topicAnalysEnabled,
      surveySuggestionsEnabled,
      page,
      postAndSurveySentiment,
      surveyLanguages
    } = this.props;

    if (Store.Page.isInError(comments)) {
      return (
        <FetchError {...comments} resourceType={CommentsResultResource.TYPE} />
      );
    }

    if (Store.Element.isInError(commentsOverview)) {
      return (
        <FetchError
          {...commentsOverview}
          resourceType={CommentsOverviewResource.TYPE}
        />
      );
    }
    let enableDefaultTranslate = false;
    if(isNotEmptyArray(surveyLanguages) && filterEnglishCodes(surveyLanguages).length) {
      enableDefaultTranslate = true;
    }
    let enabledFilters = [ "dimension", "sentiment"];
    enabledFilters = topicAnalysEnabled ? ["topic", ...enabledFilters] : enabledFilters;
    return (
      <>
        <Spin size="large" className="sticky-top" spinning={!completedFARequest}>
          {hasFaRights && (
            <BottomDrawer
              key="segment-drawer"
              initialView={this.props.currentBottomDrawerView}
              title={`${
                this.state.totalFocusAreas === 0
                  ? "No"
                  : this.state.totalFocusAreas
              } Focus Area Added`}
              footerButton={{
                display: true,
                text: "Go To Action Center",
                onClick: () => goTo("/actioncenter"),
              }}
            >
              <BottomDrawerStripedChild
                stripStack={transformFocusAreasToStripStack(focusAreas)}
                emptyStackMessage={BottomDrawerMessages.EMPTY_STACK_MESSAGE}
              />
            </BottomDrawer>
          )}

          <Comments
            participation={participation}
            commentsOverview={Store.Element.toLoadable(commentsOverview)}
            comments={comments}
            enabledFilters={enabledFilters}
            enabledCustomFilters={["addDimension"]}
            clearPrivateMessageModal={clearPrivateMessageModal}
            sendPrivateMessage={sendPrivateMessage}
            togglePrivateMessageModal={togglePrivateMessageModal}
            updatePrivateMessageText={updatePrivateMessageText}
            commentText={commentText}
            errorText={errorText}
            isNetworkRequesting={isNetworkRequesting}
            isPrivateMessageModalVisible={isPrivateMessageModalVisible}
            postId={postId}
            privateMessageText={privateMessageText}
            surveyId={surveyId}
            surveyName={surveyName}
            voteId={voteId}
            canSendPrivateMessage={canSendPrivateMessage}
            hasActionCreationRight={hasActionCreationRight}
            anonymityThreshold={anonymityThreshold}
            focusAreas={focusAreas}
            onCreateFocusArea={onCreateFocusArea}
            sort={sort}
            topicAnalysEnabled={topicAnalysEnabled}
            surveySuggestionsEnabled={surveySuggestionsEnabled}
            page={page}
            postAndSurveySentiment={postAndSurveySentiment}
            enableDefaultTranslate={enableDefaultTranslate}
          />
        </Spin>
      </>
    );
  }
}

export const mapStateToProps: MapStateToProps<
  CommentsContainerStateProps,
  OwnProps,
  State
> = (
  state: State,
  ownProps: OwnProps
): CommentsContainerStateProps & SurveyCommentsReportStateProps => {
  const filter = parseQueryString(ownProps.location.search).filter;
  const queryParameters = parseQueryString(ownProps.location.search);
  const sort = getOr(formatSort(queryParameters.sort), {});
  const comments = getExistingPage(
    state,
    CommentsResultResource.TYPE,
    CommentsResultResource.generateKey({ surveyId: ownProps.surveyId }, filter),
    PageFilter.noPagination() // fixme: handle pagination at some point!
  );
  const currentUser = getCurrentUser(state);
  const data = getAllFocusAreasFromReduxStore(state);
  const currentBottomDrawerView = getCurrentBottomDrawerView(state);
  const hasFaRights = hasFocusAreaRights(getRightsMatcher(state));
  const page = {
    number: mapOr(queryParameters.page, parseNumber, 1),
    size: 10
  };
  return {
    comments,
    commentsOverview: getResourceById(
      state,
      CommentsOverviewResource.TYPE,
      CommentsOverviewResource.generateId(
        { surveyId: ownProps.surveyId },
        filter
      )
    ),
    dimensions: getDimensions(state),
    commentText: state.getIn(["insights_survey_report", "commentText"]),
    errorText: state.getIn(["insights_survey_report", "errorText"]),
    isNetworkRequesting: state.getIn([
      "insights_survey_report",
      "isSendMessageRequesting",
    ]),
    isPrivateMessageModalVisible: state.getIn([
      "insights_survey_report",
      "isPrivateMessageModalVisible",
    ]),
    postId: state.getIn(["insights_survey_report", "postId"]),
    privateMessageText: state.getIn([
      "insights_survey_report",
      "privateMessageText",
    ]),
    voteId: state.getIn(["insights_survey_report", "voteId"]),
    parameters: getParameters(state),
    loading: Store.Page.isNotFound(comments),
    canSendPrivateMessage: checkForConversationRight(ownProps.surveyRole),
    hasActionCreationRight: checkForActionPlanCreationRight(state),
    anonymityThreshold: getAnonymityThreshold(state),
    currentUser,
    focusAreas: data,
    currentBottomDrawerView,
    hasFaRights,
    completedFARequest: isFARequestComplete(state),
    sort,
    page
  };
};

const mapDispatchToProps: CommentsContainerActionProps = {
  onFetchSurveyComments: fetchCommentResultsIfNeeded,
  onFetchSurveyCommentsOverview: fetchCommentsOverviewIfNeeded,
  onFetchFocusArea: fetchFocusAreasIfNeeded,
  onCreateFocusArea: focusAreaListActionCreators.createFocusArea,
  ...surveyReportActionCreators,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentsContainer);
export { CommentsContainer as PureCommentsContainer };
